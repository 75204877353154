import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { CartolaService } from '../../services/cartola.service';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { BinanceService } from '../../services/binance.service';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { ActionsService } from 'src/app/shared/services/actions.service';

import Swal from 'sweetalert2';
import { HoursOperationService } from '../../services/hours-operation.service';
import { Router } from '@angular/router';
import { BeneficiaryZelleComponent } from './components/beneficiary-zelle/beneficiary-zelle.component';
import { CalculatorZelleCalComponent } from './components/calculator-zelle-cal/calculator-zelle-cal.component';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { ParameterService } from '../../services/parameter.service';
import { ParametrosEnum } from 'src/app/shared/enums/parameters.enum';
import { BeneficiarioZelle } from '../../interfaces/beneficiario-zelle.interface';

@Component({
  selector: 'app-calculator-zelle',
  templateUrl: './calculator-zelle.component.html',
  styleUrls: ['./calculator-zelle.component.sass']
})
export class CalculatorZelleComponent implements OnInit{

  isVertical= false;
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if (window.innerWidth <= 768) {
      this.isVertical = true;
    } else {
      this.isVertical = false;
    }
  }
  constructor(
    private cartolaService: CartolaService,
    private store: Store<AppState>,
    private binanceService: BinanceService,
    private actionService: ActionsService,
    private hourOperations: HoursOperationService,
    private router: Router,
    private spinner: SpinnerService,
    private parameterService: ParameterService
  ){}


  @ViewChild('beneficiarioComponent') beneficiaryComponent: BeneficiaryZelleComponent | undefined;

  @ViewChild('stepper') stepper: MatStepper | undefined;

  @ViewChild('calculatorComponent') calculatorComponent: CalculatorZelleCalComponent | undefined;

  public subscription: Subscription = new Subscription();
  public user: any;
  public saldoActual = 0;
  public sobregiroDisponible :number|undefined=0;
  public saldoContable :number|undefined= 0;
  public lineaCreditoBloqueada:boolean|undefined=false;
  public lineaCredito:number|undefined=0;
  public hasLoaded:boolean =  false;
  public usdt_clp_tax: number = 0;
  public stepPass2: boolean = false;
  public stepPass3: boolean = false;
  public stepPass4: boolean = false;
  public clpCurrency: number = 0;
  public zelleCurrency: number = 0;
  public beneficiario: BeneficiarioZelle | undefined;
  public valorCambioId: number = 0;
  public zelleSellValue: number = 0;
  public zelleBuyValue: number = 0;
  public porCompZelle: number = 0;
  public descVentaZelle: number = 0;
  public spotPrice: number = 0;
  public limitSellZelle: number = 0;
  estadoOperaciones = true;
  estadoStopOperacion = true;
  ngOnInit(): void {
    this.onWindowResize();
    this.subscription.add(
      this.actionService.subjectTasaUSDT.subscribe(res=>{
        this.getCurrentValues();
        if(this.stepper && this.stepper.selectedIndex !== 0){
          Swal.fire('Tasas actualizadas', 'Se han actualizado las tasas, será regresado al primer paso', 'warning').then(()=>{});
          this.stepper.selectedIndex = 0;
        }
      })
    )
    this.subscription.add(
      this.store.select('auth').subscribe(({ user }: any) => {
        this.user = user;
        this.obtenerSaldo();
      })
    );
    this.getCurrentValues();
    this.validarEstado();
  }


  async validarEstado(){
    this.estadoOperaciones = await this.hourOperations.getEstadoOperacion('ZELLE');
    this.estadoStopOperacion = await this.hourOperations.getEstadoOperacion('ZELLE_STOP');
    console.log(this.estadoStopOperacion);
    const date = new Date().getDay();
    const message = [6,7].includes(date) ? 'Hemos cerrado operaciones, muchas gracias por preferirnos, Nos vemos el Lunes a las 9:00 am' :
    'Hemos cerrado operaciones, muchas gracias por preferirnos, Nos vemos Mañana a las 9:00 am'
    console.log(date);
    if(!this.estadoOperaciones){
      Swal.fire(
        { confirmButtonColor: '#1e5a3f',
        title: 'Operación cerrada',
        text:message,
      }
      );
      this.router.navigate(['/wallet']);
    }
    if(!this.estadoStopOperacion){
      Swal.fire(
        { confirmButtonColor: '#1e5a3f',
        title: 'Operación Pausada',
        text:'Volvemos en 10 min',
      }
      );
      this.router.navigate(['/wallet']);
    }
  }

  async obtenerSaldo(){
    if(this.user?.usuarioId){
      const {saldo,lineaCreditoId,balancePermitido,lineaBloqueada,sobregiro} = await this.cartolaService.getSaldoCliente(this.user.usuarioId);
      this.saldoActual = saldo;
      this.sobregiroDisponible=sobregiro;
      this.saldoContable = balancePermitido;
      this.lineaCreditoBloqueada=lineaBloqueada;
      this.lineaCredito=lineaCreditoId;
    }
  }

  async getCurrentValues(){
    const ref = this.spinner.start()
    const currentValues = await this.binanceService.getConversionData();
    this.usdt_clp_tax = currentValues.tasaUsdtClp;
    this.valorCambioId = currentValues.id;
    await this.parameterService.getParameter(ParametrosEnum.DESCUENTO_COM_ZELLE).then((value) => {
      this.descVentaZelle = value.valor;
    }
    );
    await this.parameterService.getParameter(ParametrosEnum.PRECIO_SPOT).then((value) => {
      this.spotPrice = value.valor;
    }
    );
    await this.parameterService.getParameter(ParametrosEnum.PORC_VENTA_ZELLE).then((value) => {
      this.porCompZelle = value.valor;
    }
    );
    this.zelleSellValue=+this.usdt_clp_tax*+this.porCompZelle;
    this.zelleBuyValue=+this.spotPrice-+this.descVentaZelle;

    this.zelleSellValue=Math.round(this.zelleSellValue*100)/100;
    this.zelleBuyValue=Math.round(this.zelleBuyValue*100)/100;
    this.spinner.stop(ref);
  }

  async returngetCurrentTax(){
    const currentValues = await this.binanceService.getConversionData();
    if(currentValues.tasaUsdtClp != this.usdt_clp_tax && this.usdt_clp_tax != 0){
      this.getCurrentValues();
      if(this.stepper && this.stepper.selectedIndex !== 0){
        Swal.fire('Tasas actualizadas', 'Se han actualizado las tasas, será regresado al primer paso', 'warning').then(()=>{});
        this.stepper.selectedIndex = 0;
        setTimeout(()=> this.calculatorComponent!.transformCurrency('clp'), 1000)
      }
    }
  }

  async validateGetCurrentValues(){
    const currentValues = await this.binanceService.getConversionData();
    let last_tax = this.usdt_clp_tax;
    if(last_tax != currentValues.tasaUsdtClp){

    }
    this.usdt_clp_tax = currentValues.tasaUsdtClp;
    this.valorCambioId = currentValues.id;
  }


  nextStep( step:number ):void {
    console.log('Step:', step);
    switch( step ){
      case 2:{
        this.stepPass2 = true
        break
      }
      case 3:{
        this.stepPass3 = true
        break
      }
      case 4: {
        this.stepPass4 = true;
        break;
      }
    }
    setTimeout(() => {
      if (this.stepper) {
        console.log('Advancing to next step');
        this.stepper.next();
      } else {
        console.log('Stepper not defined');
      }
    }, 300);
  }

  backStep(): void {
    setTimeout(() => {
      if(this.stepper){
        this.stepper.previous();
      }
    }, 300);
  }

  assignClp( clp:number ){
    this.clpCurrency = clp;
    if(this.clpCurrency == 0) this.stepPass3 = false;
  }

  assignZelle( usd:number ){
    this.zelleCurrency = usd;
    if(this.zelleCurrency == 0) this.stepPass3 = false;
  }

  assignBeneficiario( beneficiario:BeneficiarioZelle ){
    this.beneficiario = beneficiario;
    if(this.beneficiario.banco.codigoBanco==="BOFA"){
      this.limitSellZelle = 10000;
    }else{
      this.limitSellZelle = 2000;
    }
  }

  onStepChange(event: StepperSelectionEvent): void{
    this.returngetCurrentTax();
    console.log ('Step:', event.selectedIndex);

  }




}
