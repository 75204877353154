import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { loadUser } from 'src/app/auth/auth.actions';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { UserRoles } from 'src/app/shared/enums/roles-enum';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { unsetUser } from 'src/app/auth/auth.actions'
import Swal from 'sweetalert2';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { MatSidenav } from '@angular/material/sidenav';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass'],
})
export class NavbarComponent implements OnDestroy {
  isEnabledUser: boolean = false;
  userName: string = '';
  userRole: UserRoles = UserRoles.aguacate_client;
  rolesEnum = UserRoles;
  subscription: Subscription = new Subscription();
  canSeePayers : number = 0;
  private numberOfSeconds: number = 15;

  constructor(
    private store: Store<AppState>,
    private auth: CognitoService,
    private spinner: SpinnerService,
    private router: Router,
    private _idle: Idle,
    private keepAlive: Keepalive,
    private cognitoService: CognitoService,

  ) {
    this.setIdle();

    this.subscription.add(
      this.store.select('auth').subscribe(async ({ user, isLogged }) => {
        this.canSeePayers = user?.canAddPayers ? user?.canAddPayers : 0;
        const cognitoUser = await this.auth.getUser();
        const cognitoSession = await this.auth.getSession();
        let userGroup;
        if(cognitoSession){
          userGroup =
          cognitoSession['accessToken']['payload']['cognito:groups'][0];
          this.userName = cognitoUser.attributes['email'];
          this.userRole = userGroup;
        }
        if (!isLogged) {
          this.store.dispatch(
            loadUser({ email: this.userName, role: userGroup })
          );
          return;
        }
        if (user && Object.keys(user).length !== 0 && user !== null) {
          this.userName = `${user.nombre} ${user.apellidos}`;
          this.isEnabledUser = true;
        }
      })
    );
  }

  private async setIdle(){
    const user = await this.cognitoService.getUser();
    const role = user.attributes['custom:user-group'];
    if(role === UserRoles.aguacate_operator.toString()){
      this.numberOfSeconds = 14400;
    }else{
      this.numberOfSeconds=1500;
    }


    this._idle.setIdle(this.numberOfSeconds);
    this._idle.setTimeout(5);
    this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this._idle.onIdleEnd.subscribe(() => {
      console.log('end')
      this.reset();
    });
    this._idle.onIdleStart.subscribe(()=>{
      console.log('start');
    })
    this._idle.onTimeout.subscribe(async () => {
      const isAuthenticated = await this.cognitoService.isAuthenticated();

      if(isAuthenticated){
        let timerInterval: number | undefined;
        const result = await Swal.fire({
          icon: 'question',
          timerProgressBar: true,
          html: 'Llevas mucho tiempo inactivo, la sesión se cerrará en <b></b> segundos.',
          didOpen: () => {
            let b = Swal.getHtmlContainer()?.querySelector('b');
            timerInterval = window.setInterval(() => {
              if (b) {
                b.textContent =
                  String(Math.floor((Swal.getTimerLeft() || 0) / 1000)) ||'';
                }

              clearInterval(timerInterval);
            }, 1000);
          },
          willClose: () => {
            clearInterval(timerInterval);
             },
          timer: 20000,
          title: '¿Sigues ahí?',
          text: 'Llevas más de 15 minutos inactivo, se cerrará tu sesión',
          showCancelButton: true,
          showConfirmButton: true,
          allowOutsideClick: false,
          cancelButtonText: 'Cerrar sesión',
          confirmButtonText: 'Sigo aquí',
          confirmButtonColor: '#1e5a3f',
        });
        if(result.isConfirmed){
          console.log('continuar');
        }
        if(result.isDismissed && result.dismiss?.toString() === 'timer'){
          this.logout();
        }
        if(result.isDismissed && result.dismiss?.toString() ==='cancel'){
          this.logout();
        }

        this.reset();
      }else{
        this.reset();
      }


    });
    this._idle.watch();
    this.keepAlive.interval(15);
  }
  private logout(): void{
    const ref = this.spinner.start();
    this.cognitoService.signOut().then(()=> {
      this.router.navigate(['/auth/login']);
      this.spinner.stop(ref);
    })
  }
  async listenIdle(){
    const time = await this.cognitoService.getSession();
   }

   reset() {
     this._idle.watch();
   }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  signOut() {
    Swal.fire({
      iconHtml: '<img src="assets/icons/question.svg" style="width: 80px; height: 80px; margin-top:20px"/>',
      customClass: {
        icon: 'no-border animate-bounce'
      },
      title: 'Cerrar sesión',
      text: '¿Está seguro que desea cerrar la sesión?',
      showCancelButton: true,
      cancelButtonText: 'No',
      showDenyButton: false,
      confirmButtonText: 'Sí',
      confirmButtonColor: '#1e5a3f',

    }).then(async result=>{
      if(result.isConfirmed){
        let spinnerRef = this.spinner.start();
        await this.store.dispatch(unsetUser());
        this.auth
          .signOut()
          .then(() => {
            this.router.navigate(['auth/login']);
            this.spinner.stop(spinnerRef);
          })
          .catch(() => {
            this.spinner.stop(spinnerRef);
          });
      }
    });

  }

  closeSidenav(sidenav: MatSidenav) {
    sidenav.close();
  }

  redirectToExternalLink(): void {
    window.open('https://wa.me/56965474761?text=Hola,%20necesito%20asistencia', '_blank');
  }
}
